import { useDispatch } from 'react-redux';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import { Colors } from '../../enums/enums';
import VideoDialog from '../Learning/VideoDialog';
import SampleVideo from '../../assets/video_sample.jpg';

const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
const useStyles = makeStyles({
  root: {
    width: '330px',
    height: '330px',
    marginBottom: '30px',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 10px 5px rgba(223, 245, 39, 0.19)',
      cursor: 'pointer'
    }
  }
});
const FeaturedVideo = () => {
  const classes = useStyles();

  const [data, setData] = useState({ items: [] });
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    axios
      .get(
        `${YOUTUBE_PLAYLIST_ITEMS_API}?playlistId=PLCvABU_CjrRDWBL5SUUvSc20-DYTh9bPD&part=snippet,id&maxResults=40&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
      )
      .then((res) => {
        setData(res.data);
        console.log(data);
      });
  }, []);

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 4000, min: 1900 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1900, min: 1500 },
      items: 4
    },
    smallDesktop: {
      breakpoint: { max: 1500, min: 1150 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1150, min: 700 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1
    }
  };

  const handleClose = () => {
    setOpenVideoDialog(false);
  };

  const featuredVideo = data?.items[0];

  return (
    <>
      {/*TODO Uncomment and remove the temporary card*/}

      {/*<Card*/}
      {/*  elevation={0}*/}
      {/*  key={1}*/}
      {/*  className={classes.root}*/}
      {/*  onClick={() => {*/}
      {/*    setVideoId(featuredVideo?.snippet.resourceId.videoId);*/}
      {/*    setOpenVideoDialog(true);*/}
      {/*  }}*/}
      {/*  sx={{*/}
      {/*    width: 'auto',*/}
      {/*    maxWidth: '50%',*/}
      {/*    height: 'auto',*/}
      {/*    backgroundColor: Colors.BackgroundMainLighter,*/}
      {/*    boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',*/}
      {/*    borderRadius: 7,*/}
      {/*    px: 2,*/}
      {/*    mt: 2*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Typography sx={{ fontSize: '1.2rem', ml: 3, mt: 2, mb: 2, color: Colors.TextElevated, fontWeight: 'bold' }}>*/}
      {/*    Featured Content*/}
      {/*  </Typography>*/}
      {/*  <CardMedia*/}
      {/*    component="img"*/}
      {/*    sx={{ borderRadius: 2 }}*/}
      {/*    // image={*/}
      {/*    //   featuredVideo?.snippet?.thumbnails?.maxres?.url ||*/}
      {/*    //   `https://img.youtube.com/vi/${featuredVideo?.snippet?.resourceId?.videoId}/maxresdefault.jpg`*/}
      {/*    // }*/}
      {/*    image={SampleVideo}*/}
      {/*  />*/}
      {/*  <CardContent>*/}
      {/*    <Typography variant="body2" sx={{ color: Colors.TextElevated }}>*/}
      {/*      VIDEO*/}
      {/*    </Typography>*/}
      {/*    <Typography*/}
      {/*      gutterBottom*/}
      {/*      variant="h6"*/}
      {/*      component="div"*/}
      {/*      sx={{ color: Colors.TextElevated, textAlign: 'center' }}*/}
      {/*    >*/}
      {/*      {featuredVideo?.snippet?.title}*/}
      {/*    </Typography>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}

      {/*TEMPORARY*/}
      <a
        target="blank"
        rel="noreferrer"
        style={{ textDecoration: 'none', color: 'black' }}
        href={
          'https://locelleprdcacst.blob.core.windows.net/learningtools/BestHuman%20Session%20Prep%20Form%20FILLABLE.pdf'
        }
      >
        <Card
          elevation={0}
          key={2}
          className={classes.root}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            maxWidth: '30%',
            height: '430px',
            backgroundColor: Colors.BackgroundMainLighter,
            boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',
            borderRadius: 7,
            mt: 2,
            px: 1,
            cursor: 'pointer'
          }}
        >
          <CardMedia
            component="img"
            sx={{
              mt: 4, // Margin top to create space from the top
              height: '300px',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              width: '95%', // Keep width as 95% to fit well
              borderRadius: 3
            }}
            image={'https://locelleprdcacst.blob.core.windows.net/thumbnailimages/AdobeStock_363920837.jpeg'}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ color: Colors.TextElevated, fontSize: '1.6rem', textAlign: 'center', mt: 2 }}
            >
              1:1 Session Agenda (Fillable)
            </Typography>
            <Typography sx={{ fontSize: '1rem', my: 1, color: Colors.TextElevatedLighter, textAlign: 'center' }}>
              Click to open and download
            </Typography>
          </CardContent>
        </Card>
      </a>
      {/*<VideoDialog isOpen={openVideoDialog} handleClose={handleClose} videoId={videoId} />*/}
    </>
  );
};

export default FeaturedVideo;